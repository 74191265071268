define("additive-mc/routes/instance/campaigns/trigger-email/detail", ["exports", "@ember/routing/route", "@ember/service", "@additive-apps/ui/utils/dom-util"], function (_exports, _route, _service, _domUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsTriggerEmailDetailRoute = _exports.default = (_class = class InstanceCampaignsTriggerEmailDetailRoute extends _route.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor4, this);
    }
    renderTemplate() {
      this.render('instance/campaigns/trigger-email/detail', {
        into: 'instance/campaigns/trigger-email',
        outlet: 'detail'
      });
    }
    async model(params) {
      var _fetchedCampaign;
      let fetchedCampaign = this.store.peekRecord('trigger-email-campaign', params.campaign_id);
      if ((_fetchedCampaign = fetchedCampaign) !== null && _fetchedCampaign !== void 0 && _fetchedCampaign.isNew) {
        return fetchedCampaign;
      }

      // TODO: remove this after all Trigger Email campaign types are migrated to the new API
      if (!fetchedCampaign) {
        // fetch all campaigns with the new API
        await this.store.findAll('trigger-email-campaign', {
          adapterOptions: {
            useNewApi: true
          }
        });
        fetchedCampaign = this.store.peekRecord('trigger-email-campaign', params.campaign_id);

        // tranisition to the list view if the campaign is not found
        if (!fetchedCampaign) {
          return this.router.transitionTo('instance.campaigns.trigger-email');
        }
      }

      // use new API for pre-stay campaigns, add other types when available
      const useNewApi = fetchedCampaign.isNewStructure;
      return this.store.findRecord('trigger-email-campaign', params.campaign_id, {
        adapterOptions: {
          useNewApi
        }
      });

      // TODO: use this when all Trigger Email campaign types are migrated to the new API
      //return await this.store.findRecord('trigger-email-campaign', params.campaign_id);
    }
    async setupController(controller) {
      super.setupController(...arguments);
      controller._isLoading = true;
      await (0, _domUtil.nextTick)();
      // eslint-disable-next-line ember/no-controller-access-in-routes
      const editViewController = this.controllerFor('instance.campaigns.trigger-email.detail.index.edit');
      controller.setInitialEditorStep = editViewController.setInitialStep;
      controller.fetchEmailMeta.perform();
      controller._isLoading = false;
      await (0, _domUtil.nextTick)();
    }
    resetController(controller, isExiting) {
      const {
        type,
        isNewStructure
      } = controller.model;
      if (isExiting || type && !isNewStructure) {
        this.store.unloadAll('trigger-email-campaign-email');
        this.store.unloadAll('trigger-email-campaign-email-series');

        // TODO: Remove once all campaign-types use the new structure
        this.store.unloadAll('email');
        this.store.unloadAll('email-series');
      }
      super.resetController(...arguments);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});