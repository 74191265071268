define("additive-mc/controllers/instance/campaigns/email", ["exports", "@ember/controller", "@ember/service", "@glimmer/tracking", "@ember/object", "@ember/object/computed"], function (_exports, _controller, _service, _tracking, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let IntstanceCampaignsEmailController = _exports.default = (_dec = (0, _computed.not)('currentUser.isAdditiveUser'), _dec2 = (0, _computed.not)('currentUser.isPartnerUser'), _dec3 = (0, _object.computed)('router.currentRouteName'), _class = class IntstanceCampaignsEmailController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor2, this);
      _initializerDefineProperty(this, "isCreateDialog", _descriptor3, this);
      _initializerDefineProperty(this, "_isNotAdditiveUser", _descriptor4, this);
      _initializerDefineProperty(this, "_isNotPartnerUser", _descriptor5, this);
    }
    get _isReadOnly() {
      if (!this.uiAppSettings.managed) {
        return false;
      }
      if (this.uiAppSettings.managedByPartner) {
        return this._isNotPartnerUser && this._isNotAdditiveUser;
      }
      return this._isNotAdditiveUser;
    }
    get _currentItem() {
      return this.router.currentRoute.attributes;
    }
    get activeDetail() {
      const currentRouteName = this.router.currentRouteName || '';
      return currentRouteName.indexOf('email.detail') > -1 ? currentRouteName : '';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isCreateDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_isNotAdditiveUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_isNotPartnerUser", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "activeDetail", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "activeDetail"), _class.prototype), _class);
});