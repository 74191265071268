define("additive-mc/models/email-campaign-contribution", ["exports", "@ember-data/model", "@ember/template", "@ember/object", "additive-mc/utils/currency-serialization", "@ember/service", "additive-mc/models/ui-transactionable-model"], function (_exports, _model, _template, _object, _currencySerialization, _service, _uiTransactionableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Contribution = _exports.default = (_dec = (0, _model.attr)('boolean'), _dec2 = (0, _model.attr)(), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('number'), _dec7 = (0, _model.attr)(), _dec8 = (0, _model.belongsTo)('email-campaign', {
    async: false
  }), _class = class Contribution extends _uiTransactionableModel.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiTransactionManager", _descriptor, this);
      _initializerDefineProperty(this, "active", _descriptor2, this);
      _initializerDefineProperty(this, "availableLanguages", _descriptor3, this);
      _initializerDefineProperty(this, "language", _descriptor4, this);
      _initializerDefineProperty(this, "policies", _descriptor5, this);
      _initializerDefineProperty(this, "greeting", _descriptor6, this);
      _initializerDefineProperty(this, "value", _descriptor7, this);
      _initializerDefineProperty(this, "image", _descriptor8, this);
      _initializerDefineProperty(this, "campaign", _descriptor9, this);
      _defineProperty(this, "transactionCreateRecord", async () => {
        this.value = null;
        await this.uiTransactionManager.createModelRecord(this);
      });
      _defineProperty(this, "transactionConfigurationChange", (property, urlSuffix, value) => {
        this.uiTransactionManager.changeModelProperty(this, property, value, null, urlSuffix);
      });
      _defineProperty(this, "transactionAddLanguage", async (locale, adapterOptions) => {
        // Reset values
        this.policies = null;
        this.greeting = null;
        await this.uiTransactionManager.addLanguage(this, locale, adapterOptions);
      });
    }
    get policiesHtmlSafe() {
      return (0, _template.htmlSafe)(this.policies);
    }
    get amount() {
      return (0, _currencySerialization.centsToAmount)(this.value);
    }

    // Define properties which are not translatable into different languages
    get nonTranslatablePropertyKeys() {
      return ['value', 'image'];
    }

    // override default transactionUrl from UiTransactionableModel
    get transactionUrl() {
      let url = this.campaign.transactionUrl;
      return `${url}/contributions/${this.id}`;
    }
    get transactionCreateUrl() {
      let url = this.campaign.transactionUrl;
      return `${url}/contributions`;
    }
    set(key, value) {
      (0, _object.set)(this, 'value', (0, _currencySerialization.amountToCents)(value));
      return value;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiTransactionManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "active", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "availableLanguages", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "language", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "policies", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "greeting", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "image", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "campaign", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});