define("additive-mc/components/amc-campaign/email-campaign/trigger", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _service, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.trigger}}
    <div class="email-campaign-trigger flex flex-column">
      {{#unless this.hideLabel}}
        <div
          class="font-sm secondary pb2 {{if this.isLabelClickable "cpointer text-underline"}}"
          role="button"
          {{on "click" this.onLabelClick}}
        >
          {{t "emailCampaigns.steps.emails.trigger"}}
        </div>
      {{/unless}}
  
      <div class="flex items-center w-100 bg-secondary--10 br1">
        <div class="email-campaign-trigger__icon-container pa2 bg-secondary--20">
          {{svg-jar "bolt" width="20" height="20"}}
        </div>
        <div class="ml2 font-sm primary w-100">
          {{this.model.descriptions.trigger.event}}
        </div>
  
        <UiPopover as |pop|>
          <pop.trigger>
            {{svg-jar "info" class="secondary mr2" width="20" height="20"}}
          </pop.trigger>
          <pop.content>
            {{this.model.descriptions.trigger.details}}
          </pop.content>
        </UiPopover>
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "L+ouhheg",
    "block": "[[[41,[30,0,[\"trigger\"]],[[[1,\"  \"],[10,0],[14,0,\"email-campaign-trigger flex flex-column\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"hideLabel\"]]],[[[1,\"      \"],[11,0],[16,0,[29,[\"font-sm secondary pb2 \",[52,[30,0,[\"isLabelClickable\"]],\"cpointer text-underline\"]]]],[24,\"role\",\"button\"],[4,[38,2],[\"click\",[30,0,[\"onLabelClick\"]]],null],[12],[1,\"\\n        \"],[1,[28,[35,3],[\"emailCampaigns.steps.emails.trigger\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"flex items-center w-100 bg-secondary--10 br1\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"email-campaign-trigger__icon-container pa2 bg-secondary--20\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"bolt\"],[[\"width\",\"height\"],[\"20\",\"20\"]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"ml2 font-sm primary w-100\"],[12],[1,\"\\n        \"],[1,[30,0,[\"model\",\"descriptions\",\"trigger\",\"event\"]]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,4],[\"info\"],[[\"class\",\"width\",\"height\"],[\"secondary mr2\",\"20\",\"20\"]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n        \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[1,[30,0,[\"model\",\"descriptions\",\"trigger\",\"details\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[1]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"pop\"],false,[\"if\",\"unless\",\"on\",\"t\",\"svg-jar\",\"ui-popover\"]]",
    "moduleName": "additive-mc/components/amc-campaign/email-campaign/trigger.hbs",
    "isStrictMode": false
  });
  let AmcCampaignEmailCampaignTriggerComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec3 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AmcCampaignEmailCampaignTriggerComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "model", _descriptor2, this);
      _initializerDefineProperty(this, "hideLabel", _descriptor3, this);
      _initializerDefineProperty(this, "isLabelClickable", _descriptor4, this);
      _initializerDefineProperty(this, "onLabelClick", _descriptor5, this);
    }
    get trigger() {
      return this.intl.t(`emailCampaigns.${this.model.code}.trigger`);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hideLabel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isLabelClickable", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onLabelClick", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignEmailCampaignTriggerComponent);
});