define("additive-mc/components/amc-date-filter", ["exports", "@additive-apps/ui/components/ui-date-filter", "additive-mc/utils/constants", "additive-mc/utils/helpers"], function (_exports, _uiDateFilter, _constants, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class AmcDateFilter extends _uiDateFilter.default {
    constructor() {
      var _this$_initialRange, _this$_initialRange2;
      super(...arguments);
      _defineProperty(this, "minDate", _constants.MINIMUM_CALENDAR_DATE);
      if (!((_this$_initialRange = this._initialRange) !== null && _this$_initialRange !== void 0 && _this$_initialRange.start) && !((_this$_initialRange2 = this._initialRange) !== null && _this$_initialRange2 !== void 0 && _this$_initialRange2.end)) {
        this._initialRange = (0, _helpers.getDefaultFilterDates)();
      }
    }
  }
  _exports.default = AmcDateFilter;
});